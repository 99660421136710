import { all } from 'redux-saga/effects'
import leads from './leads/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'

export default function* rootSaga() {
  yield all([
    leads(),
    user(),
    menu(),
    settings(),
    staff(),    
  ])
}
