export default async function getMenuData() {
  return [


    {
      title: 'Manage Leads',
      key: 'manage-leads',
      icon: 'fe fe-user',
      url: '/manage/leads',
    },

    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
  ]
}
